<template>
  <div class="main-head">
    <template v-if="isYufabu">
      <!-- <div style="color: red; width: 100%; font-size: 24px; font-weight: bold; text-align: center;">这是预发布环境,正式环境不会显示
      </div>-->
    </template>
    <div class="head" v-if="header_flag == 1">
      <div class="head-left">
        <el-image class="elimage" :src="logourl" />
      </div>
    </div>
    <div class="head" v-if="header_flag != 1">
      <div class="head-left">
        <el-image
          class="elimage"
          :src="logourl"
          style="cursor: pointer"
          @click="head_index"
        />
        <div class="head-right" v-if="isMobile == 0">
          <div class="avater_info" v-if="token">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link span-title">
                <el-image
                  style="margin-right: 4px"
                  class="elimage"
                  :src="useravater"
                />
                <span class="bt">
                  {{ username == "null" ? usernumber : username }}
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="personalbt"
                  >个人中心</el-dropdown-item
                >
                <el-dropdown-item @click.native="loginout"
                  >安全退出</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-button v-if="!token" class="loginbt" @click="login"
            >登录</el-button
          >
          <div class="svgIcon-cont" @click="showMenu()">
            <el-image class="svgIcon-img" :src="svgIcon" />
          </div>
        </div>
      </div>
      <!-- PC-->
      <div class="head-center">
        <div
          :class="activebtid === index ? 'btitem btitemactive' : 'btitem'"
          @click="menubtclick(index, item.component, $event)"
          v-for="(item, index) in btlist"
          :key="index"
        >
          <span class="btitem_span">{{ item.name }}</span>
        </div>
      </div>

      <!-- 小屏纵向导航 -->
      <div class="show-menu">
        <div class="show-menu-navi">
          <el-collapse-transition>
            <div v-show="show">
              <div
                :class="
                  activebtid === index
                    ? 'transition-box btitemactive'
                    : 'transition-box'
                "
                v-for="(item, index) in btlist"
                :key="index"
                @click="menubtclick(index, item.component, $event)"
              >
                <span slot="title" class="btitem_span">{{ item.name }}</span>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
      <div class="head-right" v-if="isMobile == 1">
        <div
          class="admin_login"
          v-if="identifier == 2 || identifier == 4"
          @click="tourl"
        >
          管理后台登录
        </div>

        <div class="avater_info" v-if="token">
          <el-dropdown>
            <span class="el-dropdown-link span-title">
              <el-image
                style="margin-right: 4px"
                class="elimage"
                :src="useravater"
              />
              <span class="bt">
                {{ username == "null" ? usernumber : username }}
              </span>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="personalbt"
                >个人中心</el-dropdown-item
              >

              <el-dropdown-item @click.native="loginout"
                >安全退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-button v-if="!token" class="loginbt" @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken, setWebAvaterToken, getIdentifierToken } from "@/utils/auth";
const request_base_path = require("../../src/utils/base_url");
import { mapState } from "vuex";
export default {
  name: "Head",
  components: {},
  data() {
    return {
      avater1: require("@/assets/学生.png"),
      avater2: require("@/assets/老师.png"),
      avater3: require("@/assets/管理员.png"),
      avater4: require("@/assets/校外人员.png"),
      token: getToken() ? true : false,
      islog: false,
      logourl: "",
      svgIcon: require("@/assets/menu.png"),
      activeName: "first",
      header_flag: "",
      // activebtid: 0,
      schoolid: "",
      identifier: getIdentifierToken(),
      schoolinfo: {},
      btlist: [
        {
          name: "首页",
          component: "firstpage",
        },
        {
          name: "虚拟仿真实验",
          component: "virtualsimulationexperiment",
        },
        {
          name: "线上课程",
          component: "wisdompracticeteaching",
        },
        {
          name: "实验用房预约",
          component: "openroom",
        },

        {
          name: "开放实验",
          component: "openexperiment",
        },
        // {
        //   name: "安全准入",
        //   component: "safeaccess",
        // },
        // {
        //   name: "通知公告",
        //   component: "announcement",
        // },
        // {
        //   name: "新闻资讯",
        //   component: "news",
        // },
        {
          name: "直播",
          component: "livebroadcast",
        },
      ],
      isYufabu: false,
      isMobile: "", // 判断手机端字段
      username: "",
      usernumber: "",
      show: false,
    };
  },
  computed: {
    ...mapState(["websiteInfo"]),
    activebtid() {
      return this.$store.state.menuactiveid;
    },
    useravater() {
      //获取用户头像 没有就显示默认头像
      let avater = this.$store.state.useravater;
      if (!avater) {
        let userType = Number(this.$store.state.identifier[0]);
        switch (userType) {
          case 1:
            avater = this.avater1;
            break;
          case 2:
            avater = this.avater2;
            break;
          case 3:
            avater = this.avater3;
            break;
          case 4:
            avater = this.avater4;
            break;
        }
        this.$store.commit("SET_Useravater", avater);
        setWebAvaterToken(avater);
      }
      return avater;
    },
   
  },
  watch: {
    websiteInfo: {
      handler(newV, oldV) {
        this.logourl = newV.portal_head_logo;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    const pageUrl = window.location.href; // 获取页面完整地址
    if (pageUrl.indexOf("yufabu") === -1) {
      this.isYufabu = true;
    } else {
      this.isYufabu = false;
    }
    // this.isYufabu = request_base_path.pro_url === "https://shijian.hzau.edu.cn/yufabu/" ? true : false
    let header_flag = this.$route.query.header_flag;
    this.header_flag = header_flag;
    // this.menubtclick(0, 'firstpage');
    if (this._isMobile()) {
      this.isMobile = 0;
    } else {
      this.isMobile = 1;
    }
    // if (!localStorage.getItem("username")) {
    //   debugger
    //   location.reload();
    // }
    this.username = localStorage.getItem("username");
    this.usernumber = localStorage.getItem("usernumber");
  },
  mounted() {},
  methods: {
    // 菜单控制
    showMenu() {
      this.show = !this.show;
    },
    // 判断手机端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    head_index() {
      if (this.isMobile == 0) {
        this.menubtclick(2, "wisdompracticeteaching");
        this.$router.push({
          path: "/home/wisdompracticeteaching/mainpage",
        });
      } else if (this.isMobile == 1) {
        this.menubtclick(0, "firstpage");
        this.$router.push({
          path: "/home/firstpage",
        });
      }
    },
    menubtclick(id, component, event) {
      this.$store.dispatch("setmenuid", id);
      window.localStorage.setItem("menuId", id);
      this.$router.push({
        path: "/home/" + component,
      });
      this.show = false;
      // if (id == this.$store.state.menuactiveid) {
      //   // this.activebtid = id
      //   // 计算当前按钮的位置，看是否需要移动
      //   const spanLeft = event?.clientX || 0; // 当前点击的元素左边距离
      //   const divBox =
      //     document.querySelector(".head-center .btitem").clientWidth / 4; // 点击的元素一半宽度
      //   const totalWidths = document.body.clientWidth; // 屏幕总宽度
      //   const widths = totalWidths / 2; // 一半的屏幕宽度
      //   const spanRight = totalWidths - spanLeft; // 元素的右边距离
      //   const scrollBox = document.querySelector(".head-center"); // 获取最外层的元素
      //   const scrollL = scrollBox.scrollLeft; // 滚动条滚动的距离
      //   // 当元素左边距离 或者 右边距离小于100时进行滑动
      //   if (spanRight < 400 || spanLeft < 400) {
      //     scrollBox.scrollLeft = scrollL + (spanLeft - widths) + divBox;
      //     console.log("scrollBox.scrollLeft", scrollBox.scrollLeft);
      //   }
      // }
    },
    personalbt() {
      sessionStorage.setItem("router-type", false);
      this.menubtclick(8, "personalcenter");

      let mypage = {
        active: 0,
        componentId: "Virtualsimulationexperiment",
      };
      sessionStorage.setItem("my-page", JSON.stringify(mypage));
    },
    login() {
      //原地址
      this.$router.push({ path: "/login" });
    },

    async loginout() {
      const systemConfig = sessionStorage.getItem("systemConfig") || "";
      const systemInfo = JSON.parse(systemConfig);
      console.log("systemInfo", systemInfo);
      localStorage.removeItem("username");
      localStorage.removeItem("usernumber");
      try {
        let res = await this.$store.dispatch("logout");
        if (res.code === 0) {
          this.token = false;
          //console.log(request_base_path);
          if (process.env.NODE_ENV == "development") {
            // location.href = request_base_path.dev_url + 'cas-logout'
            if (this.isMobile == 0) {
              this.$router.push({ path: "/login" });
              localStorage.removeItem("login_path");
            } else if (this.isMobile == 1) {
              if (systemInfo.auth_login_out_sso_direct) {
                window.location.href = systemInfo.auth_login_out_sso_direct; //正式服需要的
                localStorage.removeItem("login_path");
              } else {
                this.$router.push({ path: "/" }); // 测试服需要的
                localStorage.removeItem("login_path");
              }
            }
          } else if (process.env.NODE_ENV == "production") {
            if (this.isMobile == 0) {
              this.$router.push({ path: "/login" }); //测试服需要的
              localStorage.removeItem("login_path");
            } else if (this.isMobile == 1) {
              if (systemInfo.auth_login_out_sso_direct) {
                window.location.href = systemInfo.auth_login_out_sso_direct; //正式服需要的
                localStorage.removeItem("login_path");
              } else {
                this.$router.push({ path: "/" }); // 测试服需要的
                localStorage.removeItem("login_path");
              }
            }

            //  this.$router.push({ path: "/" });//测试服需要的
          }
          window.localStorage.setItem("live_login_flag", "0"); // 直播
        }
      } catch (error) {}
    },
    getUrlPramas(pramas) {
      //定义一个空对象来存储路径中获取的参数
      var object = {};

      //1.获取？后面的所有内容包括问号
      var url = location.hash.split("?")[1]; //?name=嘻嘻&hobby=追剧
      // 2.截取？后面的字符串
      let urlData = url.substr(0);

      var strs = urlData.split("&");

      for (var i = 0; i < strs.length; i++) {
        object[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return object;
    },
    tourl() {
      let token = getToken();
      let params = "?schoolid=1&identifier=4&type=1&token=" + token;
      let location = "";
      let path = "";
      let href = "";
      // 判断运行环境
      if (process.env.NODE_ENV == "development") {
        let index = request_base_path.dev_url.lastIndexOf(":");
        // 通过最后一个冒号判断有没端口，重置本地api
        if (index == 5 || index == 5) {
          location = request_base_path.dev_url;
          path = token ? "teacher-admin/#/sign_on" + params : "#/login"; // 相对路径
        } else {
          // location = request_base_path.dev_url.substring(0,index) + ":9527/"; // 变更端口
          let i = window.location.origin.lastIndexOf(":");
          location = window.location.origin.substring(0, i) + ":9527/"; // 变更端口
          path = token ? "#/sign_on" + params : "#/login"; // 相对路径
        }
        href = location + path; // 本地
        // href = location + '/cas-logout';
      } else if (process.env.NODE_ENV == "production") {
        location = request_base_path.pro_url;
        path = token ? "teacher-admin/#/sign_on" + params : "#/login"; // 相对路径
        href = location + path; // 线上
        // href = location + '/cas-logout';  //正式服需要的
      }
      window.open(href, "_blank"); // 拼接外部网址
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .main-head {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    background: #fff;

    .head {
      // padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // height: 60px;
      // margin: 0 auto;

      .head-left {
        flex-basis: 245px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 10px 10px;
        .elimage {
          height: 46px;
        }
      }

      .head-center {
        display: none;
        // flex-grow: 1;
        width: 100%;
        // flex-shrink: 1;
        height: 100%;
        white-space: nowrap;
        overflow-x: scroll;
        margin-top: 20px;
        // display: ;
        // justify-content: center;
        // align-items: center;

        .btitem {
          // margin-left: 90px;
          user-select: none;
          white-space: nowrap;
          font-size: 15px;
          font-weight: 400;
          color: #555555;
          line-height: 40px;
          height: 40px;
          cursor: pointer;
          width: calc(100% / 3);
          text-align: center;
          display: inline-block;

          // float: left;
          &:first-child {
            // margin-left: 53px;
          }

          .btitem_span:hover {
            color: #3d84ff;
            border-bottom: 3px solid #3d84ff;
          }

          .btitem_span {
            // display: block;
            border-bottom: 3px solid transparent;
            padding-bottom: 7px;
          }
        }

        .btitemactive .btitem_span {
          color: #3d84ff;
          border-bottom: 3px solid #3d84ff;
          // width: 20%;
        }
      }

      .head-right {
        flex-basis: 221px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        white-space: nowrap;
        // margin-right: 20px;

        // padding-left: 20px;
        .admin_login {
          user-select: none;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          height: 32px;
          line-height: 32px;
          margin-right: 20px;
          background: #3d84ff;
          padding: 0px 10px 0px 10px;
          border-radius: 5px;
          cursor: pointer;
          width: 105px;
          text-align: center;
        }

        .avater_info {
          display: flex;
          justify-content: center;
          align-items: center;

          .span-title {
            display: flex;
            align-items: center;
          }

          .elimage {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        }

        .loginbt {
          margin-left: 30px;
          width: 85px;
          height: 32px;
          line-height: 32px;
          padding: 0px;
          background: #3d84ff;
          color: #ffffff;
        }

        .signout {
          margin-left: 12px;
          width: 85px;
          height: 32px;
          line-height: 32px;
          padding: 0px;
          background: #ffffff;
          color: #3d84ff;
        }
      }
    }
  }

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 0.01rem;
    opacity: 0;
    display: none;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    background-color: #fff;
    opacity: 0;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    width: 0.01rem;
    border-radius: 0.01rem;
    opacity: 0;
  }
  .svgIcon-cont {
    // text-align: right;
    // margin-right: 20px;
    margin-left: 20px;
    .svgIcon-img {
      width: 25px;
    }
  }
  .show-menu {
    .show-menu-navi {
      background: #fff;
      border-top: 1px solid #dedede;
      .transition-box {
        height: 55px;
        line-height: 55px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        // margin-left: 20px;
      }
    }
    .btitemactive .btitem_span {
      color: #3d84ff;
    }
  }
}

@media screen and (min-width: 620px) and (max-width: 1200px) {
  .main-head {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    background: #fff;

    .head {
      // padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      // height: 60px;
      // margin: 0 auto;

      .head-left {
        flex-basis: 245px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin-left: 20px;
        padding-top: 10px;
        .elimage {
          height: 46px;
        }
      }

      .head-center {
        // flex-grow: 1;
        width: 100%;
        // flex-shrink: 1;
        height: 100%;
        white-space: nowrap;
        overflow-x: scroll;
        // margin-top: 20px;
        // display: ;
        // justify-content: center;
        // align-items: center;

        .btitem {
          // margin-left: 90px;
          user-select: none;
          white-space: nowrap;
          font-size: 15px;
          font-weight: 400;
          color: #555555;
          line-height: 40px;
          height: 40px;
          cursor: pointer;
          width: calc(50% / 3);
          text-align: center;
          display: inline-block;

          // float: left;
          &:first-child {
            // margin-left: 53px;
          }

          .btitem_span:hover {
            color: #3d84ff;
            border-bottom: 3px solid #3d84ff;
          }

          .btitem_span {
            // display: block;
            border-bottom: 3px solid transparent;
            padding-bottom: 7px;
          }
        }

        .btitemactive .btitem_span {
          color: #3d84ff;
          border-bottom: 3px solid #3d84ff;
          // width: 20%;
        }
      }

      .head-right {
        flex-basis: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        white-space: nowrap;
        margin-right: 20px;

        // padding-left: 20px;
        .admin_login {
          user-select: none;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          height: 32px;
          line-height: 32px;
          margin-right: 20px;
          background: #3d84ff;
          padding: 0px 10px 0px 10px;
          border-radius: 5px;
          cursor: pointer;
          width: 105px;
          text-align: center;
        }

        .avater_info {
          display: flex;
          justify-content: center;
          align-items: center;

          .span-title {
            display: flex;
            align-items: center;
          }

          .elimage {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        }

        .loginbt {
          margin-left: 30px;
          width: 85px;
          height: 32px;
          line-height: 32px;
          padding: 0px;
          background: #3d84ff;
          color: #ffffff;
        }

        .signout {
          margin-left: 12px;
          width: 85px;
          height: 32px;
          line-height: 32px;
          padding: 0px;
          background: #ffffff;
          color: #3d84ff;
        }
      }
    }
  }

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 0.01rem;
    opacity: 0;
    display: none;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    background-color: #fff;
    opacity: 0;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    width: 0.01rem;
    border-radius: 0.01rem;
    opacity: 0;
  }
  .svgIcon-cont {
    display: none;
  }
  .show-menu {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .main-head {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    background: #fff;

    .head {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin: 0 auto;

      .head-left {
        flex-basis: 245px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .elimage {
          height: 46px;
        }
      }

      .head-center {
        // flex-grow: 1;
        width: 55%;
        // flex-shrink: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .btitem {
          // margin-left: 90px;
          user-select: none;
          white-space: nowrap;
          font-size: 15px;
          font-weight: 400;
          color: #555555;
          line-height: 60px;
          height: 60px;
          cursor: pointer;
          width: calc(100% / 6);
          text-align: center;

          // float: left;
          &:first-child {
            // margin-left: 53px;
          }

          .btitem_span:hover {
            color: #3d84ff;
            border-bottom: 3px solid #3d84ff;
          }

          .btitem_span {
            // display: block;
            border-bottom: 3px solid transparent;
            padding-bottom: 18px;
          }
        }

        .btitemactive .btitem_span {
          color: #3d84ff;
          border-bottom: 3px solid #3d84ff;
          // width: 20%;
        }
      }

      .head-right {
        flex-basis: 221px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        user-select: none;
        white-space: nowrap;

        // padding-left: 20px;
        .admin_login {
          user-select: none;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          height: 32px;
          line-height: 32px;
          margin-right: 20px;
          background: #3d84ff;
          padding: 0px 10px 0px 10px;
          border-radius: 5px;
          cursor: pointer;
          width: 105px;
          text-align: center;
        }

        .avater_info {
          display: flex;
          justify-content: center;
          align-items: center;

          .span-title {
            display: flex;
            align-items: center;
          }

          .elimage {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        }

        .loginbt {
          margin-left: 30px;
          width: 85px;
          height: 32px;
          line-height: 32px;
          padding: 0px;
          background: #3d84ff;
          color: #ffffff;
        }

        .signout {
          margin-left: 12px;
          width: 85px;
          height: 32px;
          line-height: 32px;
          padding: 0px;
          background: #ffffff;
          color: #3d84ff;
        }
      }
    }
  }
  .svgIcon-cont {
    display: none;
  }
  .show-menu {
    display: none;
  }
}
</style>
