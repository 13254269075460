const request_base_path = require('@/utils/base_url')

let href = ''
if (process.env.NODE_ENV == 'development') { // 开发环境
    href = request_base_path.dev_url
} else if (process.env.NODE_ENV == 'production') { // 生产环境
    href = request_base_path.pro_url
}

export default {
    /* 服务端代理 */
    baseUrl: href,
}