import axios from 'axios'
import md5 from 'js-md5'
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import Vue from 'vue'
import {
  getToken,
  removeToken,
  getIdentifierToken,
  removeIdentifierToken,
} from '@/utils/auth'

window.isReresh = false//用于判断是否刷新，不能少

/*捕获status状态码非200状态*/
const statusCodeTip = (status, msg) => {
  switch (status) {
    case 500:
      Message.error('服务器解析异常，请稍后重试')
      break
    case 502:
      Message.error('服务器解析异常，请稍后重试')
      break
    case 404:
      Message.error('请求的资源不存在')
      break

    default:
      Message.error('请求失败')
  }
}

/*捕获data状态码非0状态*/
const dataCodeTip = (status, msg) => {
  switch (status) {
    case 2:
      if (msg === "参数错误") {
        Message.error('传入数据错误,或传入空项')
      } else {
        Message.error(msg)
      }
      break
    case 402:
      console.log("**************************************", Vue.prototype.my_router)
      Message.error("该账号已停用，请联系管理员！")
      Vue.prototype.my_router.push({
        path: "/login"
      })
      break
    case 401:
      // Message.error('账号或密码错误，请重新输入！')
      Message.error(msg)
      Vue.prototype.my_router.push({
        path: "/login"
      })
      break
    case 403:
      MessageBox.confirm('系统检测到您当前尚未登录！', '信息提示', {
        confirmButtonText: '去登录',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false
      }).then(() => {
        Vue.prototype.my_router.push({
          path: "/login"
        })
      }).catch(() => {
      })
      break
    case 6:
      Vue.prototype.my_router.push({ path: '/queuePage' })
      break
    case 11:
      Vue.prototype.my_router.push({ path: '/requestillegal' })
      break
    case 16:
      Vue.prototype.my_router.push({ path: '/pageClosed' })
      break
    default:
      Message.error(msg)
  }
}

const cacheAPI = [
  {
    name: '学院',
    url: '/common/college/get-list',
    keyStr: 'college_all'
  },
  {
    name: '学科分类',
    url: '/common/type-config/get-list',
    keyStr: 'subject_all'
  },
  {
    name: '虚拟仿真项目级别',
    url: '/common/type-config/get-list',
    keyStr: 'vr_project_all'
  },
  {
    name: '课程类别',
    url: '/common/type-config/get-list',
    keyStr: 'course_all'
  },
  {
    name: '学期列表',
    url: '/common/semester/get-list',
    keyStr: 'semester_all'
  }
]

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://256864k9u8.51vip.biz:58228/api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 1 // request timeout
})

// const debounceTokenCancel = new Map()
// request interceptor
service.interceptors.request.use(
  config => {
    // const tokenKey = `${config.method}-${config.url}`
    // const cancel = debounceTokenCancel.get(tokenKey)
    // if (cancel) {
    //   cancel()
    // }
    // network: 0:外网; 1,内网
    // console.log('config----', config);
    // do something before request is sent
    const network = localStorage.getItem("network")
    const schoolid = localStorage.getItem("schoolid") * 1
    config.headers['network'] = network || 0
    config.headers['fromtype'] = 3
    config.headers['schoolid'] = schoolid || 1
    if (store.state.identifier) {
      config.headers['identifier'] = getIdentifierToken()
    }
    config.headers['Authorization'] = getToken() ? "Bearer " + getToken() : ""

    const date = new Date()
    const c_time = Math.trunc(date.getTime() / 1000)
    let sign_time = c_time.toString()
    let app_version = 'v1.0'
    config.headers['app-version'] = app_version
    config.headers['request-time'] = c_time
    config.headers['request-sign'] = md5(md5(sign_time) + app_version)

    return config
    // return new Promise(resolve => {
    //   const timer = setTimeout(() => {
    //     clearTimeout(timer)
    //     resolve(config)
    //   }, 1000)
    //   debounceTokenCancel.set(tokenKey, () => {
    //     clearTimeout(timer)
    //   })
    // })
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.status === 200) {
      if (typeof (response.data.code) == "undefined") {
        return response.data
      } else {
        if (response.data.code === 0) {
          if (response.config.params?.type === 0 && response.config.url === cacheAPI[1].url) {
            sessionStorage.setItem(cacheAPI[1].keyStr, JSON.stringify(response.data.data))
          } else if (response.config.params?.type === 27 && response.config.url === cacheAPI[2].url) {
            sessionStorage.setItem(cacheAPI[2].keyStr, JSON.stringify(response.data.data))
          } else if (response.config.params?.type === 29 && response.config.url === cacheAPI[3].url) {
            sessionStorage.setItem(cacheAPI[3].keyStr, JSON.stringify(response.data.data))
          } else if (response.config.url === cacheAPI[0].url) {
            sessionStorage.setItem(cacheAPI[0].keyStr, JSON.stringify(response.data.data))
          } else if (response.config.url === cacheAPI[4].url) {
            sessionStorage.setItem(cacheAPI[4].keyStr, JSON.stringify(response.data.data))
          }
          return response.data
        } else if (response.data.code == 403) {
          if (getIdentifierToken()) {
            Message({
              message: response.data.message,
              type: 'error',
              duration: 2 * 1000
            })
          }
          // 清缓存
          removeToken()
          removeIdentifierToken()
          // 执行退出
          // store.dispatch("logout");
          // 跳转链接
          Vue.prototype.my_router.push({
            path: "/login"
          })
          return response.data
        } else if (response.data.code == 7) {
          // Message({
          //   message: response.data.message,
          //   type: 'error',
          //   duration: 2 * 1000
          // })
          Vue.prototype.my_router.push({
            path: "/NotFound"
          })
        } else if (response.data.code == 12) {
          Message({
            message: "登录状态已失效，请重新登录！",
            type: 'error',
            duration: 2 * 1000
          })
          location.reload()
        } else {
          dataCodeTip(response.data.code, response.data.message)
          return response.data
        }
      }
    } else {
      statusCodeTip(response.status, response.data.message)
    }

  },
  error => {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Message({
        message: '网络请求超时',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(error)
    }

    if (error.toString().indexOf('Error: Network Error') !== -1) {
      Message({
        message: '网络请求错误',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(error)
    }
    // console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    return Promise.reject(error)

  }
)
export default service