import Vue from "vue"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueScrollTo from 'vue-scrollto'
import './styles/style.scss'
import './icons/index' //svgicon公共组件化
import VueDeviceDetector from 'vue-device-detector'
import { getToken } from "@/utils/auth"
import baseImg from '@/utils/index'

// 代理API
import api from "./api/api"
Vue.prototype.$api = api

Vue.prototype.$defaultImg = baseImg.baseImgUrl

// import Base64 from 'js-base64'

// 视频播放
import VideoPlayer from "vue-video-player"
import "video.js/dist/video-js.css" //videoJs的样式
import "vue-video-player/src/custom-theme.css" //vue-video-player的样式
import "videojs-flash" //引入才能播放rtmp视屏
import "videojs-contrib-hls" //引入才能播放m3u8文件
Vue.use(VideoPlayer)

Vue.use(ElementUI)
Vue.use(VueScrollTo)
Vue.use(VueDeviceDetector)

// Vue.use(Base64);
//  window.xx='哈哈哈'
// import 'lib-flexible'
Vue.config.productionTip = false
Vue.prototype.my_router = router

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  // 判断是否登录过
  const token = getToken()
  if (!token) {
    next()
  } else {
    if (to.path === "/login") {
      router.push({ path: '/home/firstpage' })
    }
    next()
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")

Vue.directive('selectScroll', {
  bind (el, binding) {
    //  如上图，我通过v-if来控制了两个select框，当没有binding.arg这个参数时，我只能监听到企业类型下的select框，所以，我通过传参控制了监听的哪个select框
    var className = '.' + binding.arg
    el.className = binding.arg
    // 获取滚动页面DOM
    const SCROLL_DOM = el.querySelector(`${className} .el-select-dropdown .el-select-dropdown__wrap`)
    // const SCROLL_DOM = el.querySelector(“.el-select-dropdown .el-select-dropdown__wrap“)
    SCROLL_DOM.addEventListener('scroll', function () {
      // 当前的滚动位置 减去  上一次的滚动位置
      // 如果为true则代表向上滚动，false代表向下滚动
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
      // 如果已达到指定位置则触发
      if (CONDITION) {
        // 将滚动行为告诉组件
        binding.value()
      }
    })
  }
})